.RankListView{

}
.RankItem{
padding-top: 5px;
}
.RankLabel{
font-size: 15px;
}
.RankLabel span{
font-weight: bold;
}
.RankNum{
  height: 25px;
  line-height: 25px;
  text-align: right;
  margin: 3px 0;
  border-radius: 5px;
}
.RankNum span{
  color: #fff1f0;
  padding-right: 5px;
}