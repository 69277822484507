.amap-info-close {
    top: 20px;
}

.InfoWindow{
    background: url("../../../../asset/zhuanshuiBj.png") left top no-repeat;
    min-height: 100px;
    min-width: 350px;
    position: relative;
    overflow: inherit;
    padding-top: 7px;
}

.InfoWindow h6{
    height: 30px;
    line-height: 30px;
    padding: 0 14px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}
.deviceboxlist{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.devicebox{
    min-width: 260px;
    background: #ffffff;
    border: 1px #2cadff solid;

}
.devicebox .header,.devicebox .footer{
    height: 50px;
}
.devicebox .header{
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 0 0 10px;
}
.devicebox .one{
    margin: 10px 0 0 10px;
}
.devicebox .bottom{
    height: 30px;
    line-height: 30px;
    margin: 0 0 0 10px;
}
.devicebox .header .sb-logo{
    float: left;
    width: 50px;
    margin: 0;
}
.devicebox .header .sb-logo img{
    width: 100%;
    height: auto;
    display: block;
}
.devicebox .header .status{
    padding-left: 15px;
    line-height: 20px;
    float: left;
}



.devicebox ul{
    list-style: none;
    padding: 0;
    margin: 0;
}
.devicebox .msbox{
    min-height: 50px;
    padding-left: 10px;
}
.devicebox .msbox .title{padding: 0}
.devicebox .msbox .status{ font-size: 14px;padding:0 }

.devicebox ul li{
    font-size: 16px;
    padding: 0 10px;
    line-height: 30px;
    height: 30px;
}
.devicebox .footer{
    height: 30px;
    display: flex;
    margin-top: 10px;
}
.devicebox .footer span{
    display: block;
    height: 30px;
    width: 50%;
    text-align: center;
    cursor: pointer;
    line-height: 30px;
}