.container {
    min-height: 100vh;
}

.header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;
    background: #ffffff;
    padding-left: 0;
}

.trigger {
    padding: 0 24px;
    font-size: 16px;
    cursor: pointer;
    transition: color 0.3s;
    transform: translateY(1px);
}

.trigger:hover {
    color: #1890ff;
}

.headerLeft {
    display: flex;
    align-items: center;
    font-size: 19px;
    margin: 0 !important;
}

.userName {
    font-size: 16px;
    cursor: pointer;
}

.bigImg img {
    height: 50px;
}

.smallImg img {
    height: 23px !important;
}
.badgeColor,
.badgeColorDark {
    display: block;
}
.badgeColor :global .ant-badge {
    color: rgba(0, 0, 0, 0.65);

}
.badgeColorDark :global .ant-badge {
    color: rgba(255, 255, 255, 0.65);

}