.hideToolBar {
  height: 0!important;
}
.toolBarWrap {
  background-color: #fff;
  width: 100%;
  align-self: flex-end;
  height: 56px;
  display: flex;
  position: absolute;
  bottom: 0;
  transition: height 0.5s ease;
  justify-content: center;
  align-items: center;
  & > button {
    position: absolute;
    top: 12px;
    right: 40px;
  }
  & .toolBtn {
    flex-direction: column;
    cursor: pointer;
    width: 72px;
    justify-content: center;
    align-items: center;
    height: 48px;
    color: rgba(23, 26, 29, 0.85);
    & > span:last-of-type {
      font-size: 12px;
      opacity: 0.56;
    }
    &:hover {
      background-color: #dcdcdc;
      border-radius: 4px;
    }
  }
  & svg {
    font-size: 18px;
  }
}

.mainView {
  flex: 1;
  overflow: hidden;
  position: relative;
  & .higherMainUserInfo {
    bottom: 60px;
    // transition: bottom 0.5s ease;
  }
}

.confInfo {
  position: absolute;
  top: 0;
  z-index: 11;
  font-size: 12px;
  padding-left: 4px;
  color: rgba(23, 26, 29, 0.85);
  left: 0;
  flex-direction: column;
}

.blockWrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  flex-direction: column;
  background-color: #dce1e6;
  position: relative;
  & > div:first-of-type {
    height: 32px;
    width: 100%;
    background-color: rgb(245, 247, 250);
    & > span:first-of-type {
      position: relative;
      left: 4px;
      top: 4px;
    }
  }
}

.fullscreen {
  position: absolute;
  right: 16px;
  top: 4px;
  align-items: center;
  cursor: pointer;
  color: var(--common_level1_base_color, #171a1d);
  font-size: 14px;
  & span {
    margin-right: 4px;
  }
}
.smallVideoItems {
  display: none;
  width: 100%;
  padding-bottom: 12px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #f5f7fa;
  border-radius: 6px;
  min-height: 120px;
  max-height: 250px;
  overflow-y: scroll;
  padding-left: 20px;
}

.smallVideoItem {
  width: 184.889px;
  height: 104px;
  position: relative;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  // overflow: hidden;
  display: flex;
  background-color: #dce1e6;
  & :global(.dtd-avatar) {
    display: none;
  }
}

.smallViewStatus {
  position: absolute;
  bottom: 4px;
  left: 4px;
  padding: 0 4px;
  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 4px;
  background-color: hsla(0,0%,100%,0.8);
  z-index: 1;
  transition: bottom 0.5s ease;
  & > span:last-of-type {
    font-size: 12px;
    margin-left: 2px;
    color: #171a1d;
  }
}

.avatar {
  :global(.dtd-avatar) {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & video {
    display: none;
  }
}

.camera {
  :global(.dtd-avatar) {
    display: none;
  }
  & video {
    border-radius: 6px;
    display: inline-block;
  }
}

.stopShare {
  & :global(.deviceIcon) {
    color: #fb602d !important;
  }
}

.smallViewActions {
  position: absolute;
  right: 4px;
  bottom: 4px;
  padding: 0 4px;
  border-radius: 4px;
  background-color: hsla(0,0%,100%,0.8);
  cursor: pointer;
  z-index: 1;
}

.userId {
  position: absolute;
  top: 4px;
  left: 4px;
  font-size: 12px;
  color: #fb602d;
}

.speaking {
  &::after {
    content: '';
    position: absolute;
    top: -1.5px;
    border-radius: 6px;
    left: -1.5px;
    width: calc(100% + 3px);
    height: calc(100% + 3px);
    border: 1.5px solid green;
  }
}

@media screen and (max-width: 575px) {
  .toolBarWrap {
    & button:last-of-type {
      right: 8px;
    }
    & .toolBtn {
      width: 90px;
    }
  }
  .smallVideoItems {
    padding-left: 0;
  }
  .confInfo {
    padding-left: 0;
    transform: scale(0.8);
  }
  .settings {
    & :global(.dtd-modal) {
      margin-top: 0;
    }
    & :global(.dtd-modal-content) {
      max-width: 100vw;
      max-height: 100vh;
    }
  }
}

.settings {
  & :global(.dtd-modal-content) {
    padding: 0;
  }
  & :global(.dtd-menu) {
    background-color: #f9fafb;
  }
  & :global(.dtd-divider-vertical) {
    height: 100%;
  }
  & :global(.dtd-divider-horizontal) {
    margin: 10px 0;
  }
  .settingMenu {
    border-radius: 8px 0 0 8px;
    padding: 10px;
    background-color: #f9fafb;
    border-right: 1px solid var(--common_line_light_color, rgba(126, 134, 142, 0.16));
  }
  .settingDetail {
    padding: 10px;
    border-radius: 0 8px 8px 0;
    background-color: #fff;
    & :global(.dtd-form) {
      position: relative;
    }
    .tip {
      font-size: 12px;
    }
  }
  & :global(.dtd-modal-close) {
    top: 14px;
    cursor: pointer;
  }
  & :global(.dtd-form .dtd-form-item) {
    margin-bottom: 12px;
  }
  .apply {
    position: absolute;
    right: 0;
    cursor: pointer;
    bottom: 12px;
  }
  .testDevice {
    & :global(.dtd-form-item-control) {
      max-width: 70%;
    }
    & :global(.dtd-btn-link) {
      position: absolute;
    }
  }
  .videoSetting {
    padding: 14px;
    padding-top: 0;
    .videoWrapper {
      margin-bottom: 12px;
      border-radius: 6px;
      width: 100%;
      height: 250px;
    }
    & video {
      width: 100%;
      height: 100%;
      border-radius: 6px;
      object-fit: cover;
    }
  }
}

.qualityItem {
  width: 100%;
  flex-direction: row;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin-bottom: 12px;
  & > div {
    width: 100%;
    padding: 4px 6px;
    &:first-of-type {
      background-color: #dce1e6;
      font-size: 14px;
    }
  }
  & .qualityLabel {
    font-size: 12px;
  }
}
.disconnectIconWrapper {
  margin: 0 8px;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  overflow: hidden;
  :hover {
    background-color: var(--btm-toolbar-btn-bg-color);
    cursor: pointer;
  }
  .disconnectIcon {
    width: 100%;
    height: 100%;
  }
}

.cardWrapper {
  width: 100%;
  height: 100%;
  min-width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  .labelWrap {
    flex-shrink: 1;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    .label {
      margin: 8px 0;
    }
  }
  .valueWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    .value {
      margin: 8px 0;
      white-space: nowrap;
    }
  }
}
.networkIcon {
  width: 24px;
  height: 24px;
}

.networkTrigger {
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
