.wrap {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  justify-content: center;
}

.demoTitle {
  position: absolute;
  top: 20px;
  left: 20px;
}

.testNetwork {
  position: absolute;
  top: 18px;
  left: 170px;
}

.main {
  width: 80vw;
  max-height: 550px;
  height: 50vh;
  opacity: 1;
  /* 自动布局 */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
}

.previewWrap {
  position: relative;
  width: 800px;
  max-width: 80vw;
  height: 100%;
  border-radius: 12px;
  opacity: 1;
  background: #dce1e6;
  box-shadow: 0px 0px 60px 0px rgba(126, 153, 178, 0.3);
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  & > div:first-of-type {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}



.joinPanel {
  width: 320px;
  border-radius: 12px;
  opacity: 1;
  /* 自动布局 */
  background: #ffffff;
  box-shadow: 0px 0px 60px 0px rgba(126, 153, 178, 0.3);
  z-index: 1;
  :global(.dtd-card-head) {
    padding: 0 16px;
  }
  :global(.dtd-card-head-title) {
    padding: 8px 0;
  }
  :global(.dtd-card-body) {
    padding: 16px;
    padding-bottom: 0;
  }
  & button {
    width: 100%;
  }
}

.devices {
  height: 40px;
  background-color: #ffffff;
  position: absolute;
  bottom: 8px;
  display: flex;
  width: 160px;
  flex-direction: row;
  align-items: center;
  border-radius: 6px;
  justify-content: center;
}

.camera {
  :global(.dtd-avatar) {
    display: none;
  }
  & video {
    display: inline-block;
  }
}

.avatar {
  :global(.dtd-avatar) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & video {
    display: none;
  }
}

@media screen and (max-width: 575px) {
  .main {
    flex-direction: column;
  }
  .previewWrap {
    min-height: 30vh;
    margin-bottom: 4vh;
    height: 40vh;
    position: relative;
  }
  .joinPanel {
    width: 82vw;
    & :global(.dtd-form-item) {
      margin-bottom: 12px;
    }
    & :global(.dtd-form-vertical .dtd-form-item-row) {
      flex-direction: row;
      flex-wrap: nowrap;
      & :global(.dtd-form-item-label) {
        max-width: 20%;
      }
      & input {
        max-width: 80%;
      }
    }
  }
  .avatar {
    :global(.dtd-avatar) {
      width: 80px;
      height: 80px;
    }
  }
}