.engineer-list-box {
    position: relative;
    color: white;
    width: 650px;
    /*background-color: rgba(15, 42, 165, 0.6);*/
    background: url("../../../../asset/multi_engineer_bg.png") top left/cover no-repeat;
    padding: 24px 0 10px 16px;
}

/*.engineer-list-box:before {*/
/*    content: "";*/
/*    display: inline-block;*/
/*    width: 73px;*/
/*    height: 108px;*/
/*    background: url("../../../asset/point_to.png");*/
/*    background-size: 73px 108px;*/
/*    position: absolute;*/
/*    left: -30px;*/
/*    z-numerical: 99999999;*/
/*}*/

.engineer-list-box .amap-info-close {
    top: 12px;
}

.engineer-list-title {
    font-weight: bold;
    font-size: 16px;
}

.engineer-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    cursor: pointer;
}

.engineer-avatar-img {
    min-width: 60px;
    height: 7.40741vh;
    border-radius: 8px;
    object-fit: contain;
}

.engineer-list-item {
    width: 45%;
    display: flex;
    margin: 16px 16px 0 0;
    padding-bottom: 12px;
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
}

.engineer-name-department {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.engineer-list-name {
    width: 200px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.engineer-list-department {
    width: 200px;
    font-size: 14px;
    color: #B6BBED;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
