.fromContainer {
    max-width: 380px;

    min-width: 280px;
    min-height: 160px;

    /*position: absolute;*/
    /*left: 50%;*/
    /*top: 50%;*/
    /*transform: translate(-50%, -50%);*/

    border-radius: 6px;
}

.verifyImage {
    width: 100%;
}
.verifyArea{

}
