.workorderInfoItem {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
}

.workorderInfoItem p {
    color: white;
    margin: 0;
}
