canvas {
    width: 100% !important;
}

#barContainer {
    height: 100%;
}

.ant-pro-card-ghost {
    height: 100%;
}

