.hdlabel{
    padding: 0 10px;
}
.bdlabel {
    text-align: left;
    padding-left:10px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
}
.bdlabelright{
    text-align: right;
    font-size: 14px;
}