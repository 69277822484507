.Container{
    width: 1200px;
    position: relative;
    top: 42%;
    left: 50%;
    margin: -250px 0 0 -600px;
}
.logosBox{
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.logoBg{}
.logoMsg{}
.boxContainer{
    width: 1200px;
    height: 520px;
    background: #fff;
    padding: 15px;
    border-radius: 7px;
    position: relative;
    box-shadow: #b2ccef 0 0 15px;
}
.fromContainer {
    max-width: 380px;
    min-width: 280px;
    min-height: 160px;
    border-radius: 6px;
    position: absolute;
    top: 90px;
    left: 123px;
}
.fromjieshImg {
    position: absolute;
    top: 90px;
    right: 120px;
}
.fromjieshImg img{
    width: 423px;
}
.verifyImage {
    width: 100%;
}
.verifyArea{

}
