p {
    margin: 0 !important;
}

.amap-logo {
    display: none !important;
}


/*点击弹出层样式 start*/
.amap-info-content {
    position: relative;
    border: none !important;
    margin: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 8px;
}

.bottom-center .amap-info-sharp {
    border-radius: 3px;
}

/*点击退出层样式 end*/

.text_style {
    color: #FFFFFF;
    font-size: 14px;
    padding: 12px 15px;
}

.engineer_box {
    min-width: 368px;
    background-color: rgba(15, 42, 165, 0.6);
}

.engineer_department {
    background: linear-gradient(to right, #2CADFF, #1F62C0, #1343A3);
    padding: 10px;
}

.department_name {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: bold;
}

.engineer_info {
    display: flex;
    padding: 15px;
}

.engineer_info img {
    width: 56px;
    height: 56px;
    margin-right: 14px;
    border-radius: 50%;
}

.engineer_name_mark {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.engineer_mark {
    color: #FFFFFF;
    font-size: 14px;
}

.engineer_name {
    color: #FFFFFF;
    font-size: 18px;
    font-weight: bold;
}

.now_work {
    margin: 8px 0;
}

.today_work {
    padding-top: 0;
}

.customer_department, .customer_name, .address {
    padding: 8px 15px;
}

.customer_department {
    display: flex;
}

.detail {
    cursor: pointer;
    min-width: 369px;
    background-color: #2CADFF;
    text-align: center;
    padding: 10px 0;
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 12px;
}

.maker_box {
    position: relative;
}

.maker_name {
    position: absolute;
    width: 28px;
    top: -6px;
    left: 18px;
    font-weight: bold;
}

.department_maker_name {
    width: 120px;
    position: absolute;
    top: -10px;
    left: -6px;
    color: #FFFFFF;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.department_maker_logo {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.search_ipt {
    position: absolute;
    top: 100px;
    width: 30%;
}

#customer-department {
    display: inline-block;
    width: 100%;
    flex: 7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
