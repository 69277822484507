.engineer_dialog_mask_layer {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(50, 50, 50, 0.3);
    z-index: 999;
}

.engineer_dialog_box {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}
