.systemDataItem {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 15px;
    cursor: pointer;
}

.systemDataItem p:nth-child(1) {
    color: #333333;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}

.systemDataItem p:nth-child(2) {
    color: #1890ff;
    font-weight: bolder;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}
