.divHeight20{
  height: 20px;
}
.cordBox{
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.cordBoxHd,.cordBoxBd{
  font-size: 14px;
  color: #fff1f0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.cordBoxHd{}
.cordBoxBd{}
.cordBoxTip{
  font-size: 14px;
}
.cordBoxBdNum{
  font-size: 24px;
  height: 40px;
  line-height: 40px;
}
.cordBoxBdTip{
  font-size: 14px;
  height: 40px;
  line-height: 40px;
}



.work_statistics_num_box{
  margin-top: 30px;
}
.work_statistics_label{
  font-size: 18px;
  text-align: center;
  height: 70px;
  line-height: 70px;
  background: #cfdfff;
  border-radius: 10px 10px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.work_statistics_label img{
  display: block;
  width: 25px;
  height: 25px;
  margin-right: 10px;
}
.work_statistics_num{
  font-size: 35px;
  font-weight: bold;
  color: #0075eb;
  text-align: center;
  height: 100px;
  line-height: 60px;
  background: #cfdfff;
  border-radius: 0 0 10px 10px;
}
.work_statistics_form {

}